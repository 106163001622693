import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import { ResponsivePie } from '@nivo/pie';
import React from 'react';

import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.
const styles= theme => ({
  card: {
    minWidth: 275,
    maxWidth: 500,
    background: 'none',
    boxShadow: 'none',
  },
});


function Pie(props) {
  const classes = props.classes;
  const height = props.height ? props.height : 300;

  return (
    <Card className={classes.card}>
        <CardContent style={{ height, fontWeight: 'bold' }}>
          <ResponsivePie
            data={props.data}
            margin={{
              top: 40,
              right: 120,
              bottom: 40,
              left: 120,
            }}
            innerRadius={0.5}
            padAngle={3}
            cornerRadius={3}
            colors={d => d.color}
            borderColor={{ from: 'color', modifiers: [ [ 'darker', 0.2 ] ] }}
            radialLabelsSkipAngle={10}
            radialLabelsTextXOffset={6}
            radialLabelsTextColor='#333333'
            radialLabelsLinkOffset={0}
            radialLabelsLinkDiagonalLength={16}
            radialLabelsLinkHorizontalLength={24}
            radialLabelsLinkStrokeWidth={2}
            radialLabelsLinkColor={{ from: 'color' }}
            slicesLabelsSkipAngle={10}
            slicesLabelsTextColor='#333333'
            animate={true}
            motionStiffness={90}
            motionDamping={15}
          />
        </CardContent>
    </Card>
  );
}

export default withRoot(withStyles(styles)(Pie));
