import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Button, Typography, Card, CardContent } from '@material-ui/core';
import withRoot from '../withRoot';
import NotificationsIcon from '@material-ui/icons/NotificationsTwoTone';

import SignUp from './signup';


const styles = theme => ({
  card: {
    minWidth: 275,
    marginTop: 20,
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[3],
  },
  card2: {
    minWidth: 275,
    marginTop: 20,
    backgroundColor: 'transparent',
    boxShadow: 'none',
  },
  content: {
    justifyContent: 'space-between',
  },
  notification: {
    color: 'gray'
  },
  fetchinfo: {
    fontSize: 14,
  },
  title: {
    fontWeight: 500,
    marginTop: 5
  },
  ctaText: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  subscribeIcon: {
    color: theme.palette.primary.main,
    marginRight: 15,
  },
  CTAbutton: {
    padding: 10,
    color: theme.palette.primary.main,
    border: `solid 2px ${theme.palette.primary.main}`,
    background: theme.palette.background.paper
  }

});

function Snippet(props) {
  const { classes } = props;

  return (
    <Grid container spacing={5}>
      <Grid item md={6} xs={12}>
        <Card className={classes.card}>
          <CardContent>

            <Typography className={classes.fetchinfo} color="textSecondary">
              {props.message}
            </Typography>
            <Typography variant="h5" className={classes.title}>
              {props.site}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
      <Grid item md={6} xs={12}>
        <Card className={classes.card2}>
          <CardContent>
            <Typography className={classes.ctaText} color="textSecondary">
              Want to analyse and monitor this site?
            </Typography>
            <SignUp
              CTAcomponent={
                <Button className={classes.CTAbutton}>
                  <NotificationsIcon className={classes.subscribeIcon} />
                  Sign Up for Early Beta
                </Button>
              }
            />
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
}

Snippet.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRoot(withStyles(styles)(Snippet));
