import { withStyles } from '@material-ui/core/styles';
import React from 'react';

import Typography from '@material-ui/core/Typography';
import FailIcon from '@material-ui/icons/CancelOutlined';
import SuccessIcon from '@material-ui/icons/CheckCircleOutline';
// import { Avatar } from '@material-ui/core';

const styles = theme => ({
  root: {
    width: '100%',
    marginBottom: 15,
    border: `solid 1px ${theme.palette.background.default}`,
    borderRadius: theme.shape.borderRadius,
    padding: 15,
  },
  title: {
    fontWeight: 500,
  },
  description: {
    letterSpacing: 0,
    marginLeft: 30,
  },
  icon: {
    position: 'relative',
    top: 4,
    marginRight: 10,
  },
  passed: {
    color: '#61cdbb',
  },
  failed: {
    color: theme.palette.error.main,
  },
});

function subSectionSnippet(props) {
  const classes = props.classes;

  return (
    <div className={classes.root}>
      <Typography variant='subtitle1' className={classes.title}>
        <span className={classes.icon}>
          {props.test.pass ? (
            <SuccessIcon className={classes.passed} fontSize='small' />
          ) : (
            <FailIcon fontSize='small' className={classes.failed} />
          )}
        </span>
        {props.title}
      </Typography>
      <Typography className={classes.description}>
        {props.test.score_description}
      </Typography>
    </div>
  );
}

export default withStyles(styles)(subSectionSnippet);
