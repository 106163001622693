import { ResponsiveSankey } from '@nivo/sankey';
import React from 'react';

import { Paper } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';

// make sure parent container have a defined height when using responsive component,
// otherwise height will be 0 and no chart will be rendered.
// website examples showcase many properties, you'll often use just a few of them.
function Sankey(props) {
  const classes = props.classes;
  const data = props.data;

  return (
    <Paper className={classes.container}>
      <ResponsiveSankey
        data={data}
        align='center'
        margin={{ top: 100, right: 40, bottom: 40, left: 100 }}
        animate={true}
        nodeOpacity={1}
        nodeThickness={18}
        nodeBorderColor='inherit:darker(0.8)'
        linkHoverOthersOpacity={0.1}
        enableLinkGradient={true}
        labelPosition='outside'
        linkBlendMode='difference'
        labelOrientation='horizontal'
        labelPadding={16}
        labelTextColor='inherit:darker(1)'
      />
    </Paper>
  );
}

const styles = theme => ({
  container: {
    height: 600,
    width: '100%',
    display: 'block',
    boxShadow: 'none',
    fontFamily: theme.typography.fontFamily,
  },
});

export default withRoot(withStyles(styles)(Sankey));
