import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import {
  ComposableMap,
  Geographies,
  Geography,
  Marker,
  Markers,
  ZoomableGroup,
} from 'react-simple-maps';
import withRoot from '../withRoot';

const styles = theme => ({
  container: {
    width: '100%',
    maxHeight: 600,
    display: 'block',
    boxShadow: 'none',
    fontFamily: theme.typography.fontFamily,
  },
});

// interface CustomSVGMarkersProps {
//   classes: Record<string, string>;
//   markers: Array<{ name: string; markerOffset: number }>;
//   countries: {
//     [country: string]: {
//       color: string;
//     };
//   };
// }

class CustomSVGMarkers extends React.Component {

  getColor(country) {
    if (this.props.countries[country]) {
      return this.props.countries[country].color;
    } else {
      return '#ECEFF1';
    }
  }

  render() {
    return (
      <div className={this.props.classes.container}>
        <ComposableMap
          projectionConfig={{
            scale: 205,
            rotation: [-11, 0, 0],
          }}
          width={980}
          height={551}
          style={{
            width: '100%',
            height: 'auto',
          }}
        >
          <ZoomableGroup center={[-30, 60]} zoom={1.33}>
            {/* <ZoomableGroup center={[0, 20]} zoom={1}>  ORIGINAL SETUP*/}

            <Geographies disableOptimization geography={this.props.worldmap}>
              {(geos, proj) =>
                geos.map((geo, i) => (
                  <Geography
                    key={i}
                    // key={geo.id + i}
                    geography={geo}
                    projection={proj}
                    style={{
                      default: {
                        fill: this.getColor(geo.properties.ISO_A2),
                        stroke: '#607D8B',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                      hover: {
                        fill: '#CFD8DC',
                        stroke: '#607D8B',
                        strokeWidth: 0.75,
                        outline: 'none',
                      },
                    }}
                  />
                ))
              }
            </Geographies>

            <Markers>
              {this.props.markers.map((marker, i) => (
                <Marker
                  key={i}
                  marker={marker}
                  style={{
                    default: { stroke: '#455A64' },
                    hover: { stroke: '#FF5722' },
                    pressed: { stroke: '#FF5722' },
                  }}
                >
                  <g transform='translate(-12, -24)'>
                    <path
                      fill='none'
                      strokeWidth='2'
                      strokeLinecap='square'
                      strokeMiterlimit='10'
                      strokeLinejoin='miter'
                      d='M20,9c0,4.9-8,13-8,13S4,13.9,4,9c0-5.1,4.1-8,8-8S20,3.9,20,9z'
                    />
                    <circle
                      fill='none'
                      strokeWidth='2'
                      strokeLinecap='square'
                      strokeMiterlimit='10'
                      strokeLinejoin='miter'
                      cx='12'
                      cy='9'
                      r='3'
                    />
                  </g>
                  <text
                    textAnchor='middle'
                    y={marker.markerOffset}
                    style={{
                      // fontFamily: "Roboto, sans-serif",
                      fill: '#607D8B',
                      stroke: 'none',
                    }}
                  >
                    {/* {marker.name} */}
                  </text>
                </Marker>
              ))}
            </Markers>
          </ZoomableGroup>
        </ComposableMap>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(CustomSVGMarkers));
