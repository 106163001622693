import IconButton from '@material-ui/core/IconButton';
import Paper from '@material-ui/core/Paper';
import {  withStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableFooter from '@material-ui/core/TableFooter';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import FirstPageIcon from '@material-ui/icons/FirstPage';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LastPageIcon from '@material-ui/icons/LastPage';
import React from 'react';

import { trackerCategoryRepr } from '../utils';

const actionsStyles = theme => ({
  root: {
    flexShrink: 0,
    color: theme.palette.text.secondary,
    marginLeft: theme.spacing(1) * 2.5,
  },
  tableCell: {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '300px',
  },
  linkColor: {
    color: `${theme.palette.secondary.main} !important`,
  },
});

// interface TablePaginationActionsProps {
//   classes: Record<string, string>;
//   theme: {
//     direction: string;
//   };
//   page: number;
//   count: number;
//   rowsPerPage: number;
//   onChangePage: (event: any, page: number) => void;
// }

class TablePaginationActions extends React.Component {
  handleFirstPageButtonClick = event => {
    this.props.onChangePage(event, 0);
  }

  handleBackButtonClick = event => {
    this.props.onChangePage(event, this.props.page - 1);
  }

  handleNextButtonClick = event => {
    this.props.onChangePage(event, this.props.page + 1);
  }

  handleLastPageButtonClick = event => {
    this.props.onChangePage(
      event,
      Math.max(0, Math.ceil(this.props.count / this.props.rowsPerPage) - 1),
    );
  }

  render() {
    const { classes, count, page, rowsPerPage, theme } = this.props;

    return (
      <div className={classes.root}>
        <IconButton
          onClick={this.handleFirstPageButtonClick}
          disabled={page === 0}
          aria-label='First Page'
        >
          {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
        </IconButton>
        <IconButton
          onClick={this.handleBackButtonClick}
          disabled={page === 0}
          aria-label='Previous Page'
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowRight />
          ) : (
            <KeyboardArrowLeft />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleNextButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Next Page'
        >
          {theme.direction === 'rtl' ? (
            <KeyboardArrowLeft />
          ) : (
            <KeyboardArrowRight />
          )}
        </IconButton>
        <IconButton
          onClick={this.handleLastPageButtonClick}
          disabled={page >= Math.ceil(count / rowsPerPage) - 1}
          aria-label='Last Page'
        >
          {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
        </IconButton>
      </div>
    );
  }
}

const TablePaginationActionsWrapped = withStyles(actionsStyles, {
  withTheme: true,
})(TablePaginationActions);

let counter = 0;

// interface Row {
//   id: number;
//   name: string;
//   category: string;
//   company: string;
//   source: string;
// }

function createRow({ trackerInfo, url }) {
  counter += 1;
  let name = '';
  let category = '';
  let company = '';
  let source = '';

  try {
    if (trackerInfo) {
      name = trackerInfo.name;
      category = trackerCategoryRepr(trackerInfo.category);
      company = trackerInfo.company;
    }
    if (url) {
      source = url;
    }
  } catch (e) {
    console.log(e);
  }
  return { id: counter, name, category, company, source };
}

function TruncateLink(props) {
  const u = new URL(props.url);
  return (
    <a href={u} style={{color: '#444'}}>
      {`${u.hostname}...`}
    </a>
  );
}

const styles = theme => ({
  root: {
    width: '100%',
    boxShadow: 'none',
    marginTop: theme.spacing(1) * 3,
  },
  table: {
    minWidth: 500,
  },
  tableWrapper: {
    overflowX: 'auto',
  },
  tableHeader: {
    color: theme.palette.grey[500],
  },
});

// interface TrackerTableProps {
//   classes: Record<string, string>;
//   trackers: Array<{
//     trackerInfo: string;
//     url: string;
//   }>;
// }

// interface TrackerTableState {
//   rows: Row[];
//   page: number;
//   rowsPerPage: number;
// }

class TrackerTable extends React.Component{
  constructor(props) {
    super(props);
    this.state = {
      rows: this.props.trackers.map(t =>
        createRow({ trackerInfo: t.trackerInfo, url: t.url }),
      ),
      page: 0,
      rowsPerPage:
        this.props.trackers.length >= 10 ? 10 : this.props.trackers.length,
    };
  }

  handleChangePage = (event, page) => {
    this.setState({ page });
  }

  handleChangeRowsPerPage = event => {
    this.setState({ page: 0, rowsPerPage: event.target.value });
  }

  render() {
    const { classes } = this.props;
    const { rows, rowsPerPage, page } = this.state;
    const emptyRows =
      rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
      <Paper className={classes.root}>
        <div className={classes.tableWrapper}>
          <Table className={classes.table}>
            <TableBody>
              <TableRow>
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.tableHeader}
                >
                  Service
                </TableCell>
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.tableHeader}
                >
                  Category
                </TableCell>
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.tableHeader}
                >
                  Company
                </TableCell>
                <TableCell
                  component='th'
                  scope='row'
                  className={classes.tableHeader}
                >
                  Source
                </TableCell>
              </TableRow>
              {rows
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map(row => (
                  <TableRow key={row.id}>
                    <TableCell align='left'>{row.name}</TableCell>
                    <TableCell align='left'>{row.category}</TableCell>
                    <TableCell align='left'>{row.company}</TableCell>
                    <TableCell className={classes.tableCell} align='left'>
                      <TruncateLink url={row.source} classes={classes} />
                    </TableCell>
                  </TableRow>
                ))}
              {emptyRows > 0 && (
                <TableRow style={{ height: 48 * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              <TableRow>
                <TablePagination
                  rowsPerPageOptions={[5, 10, 25]}
                  colSpan={12}
                  count={rows.length}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  SelectProps={{
                    native: true,
                  }}
                  onChangePage={this.handleChangePage}
                  onChangeRowsPerPage={this.handleChangeRowsPerPage}
                  ActionsComponent={TablePaginationActionsWrapped}
                />
              </TableRow>
            </TableFooter>
          </Table>
        </div>
      </Paper>
    );
  }
}

export default withStyles(styles)(TrackerTable);
