import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import withRoot from '../withRoot';
import {
  Avatar,
  Grid,
  Typography,
  Grow
} from '@material-ui/core';

import FingerPrintIcon from '@material-ui/icons/FingerprintTwoTone';
import LockIcon from '@material-ui/icons/LockTwoTone';
import ArrowUpIcon from '@material-ui/icons/ChangeHistoryTwoTone';
import ArrowDownIcon from '@material-ui/icons/DetailsTwoTone';

import Map from '../charts/map';
import PieChart from '../charts/PieChart';
import SankeyChart from '../charts/SankeyChart';
import SiteFetchinfo from '../components/sitefetchinfo';
import TrackerTable from '../components/genericTrackersTable';
import SubSectionSnippet from '../components/subSection';
import Features from '../layout/Features';
import DivHeader from '../layout/DivHeader'
import { getFormattedDate } from '../utils';
import Base from '../layout/base';


const styles = theme => ({
  "@keyframes slide": {
    from: {
      opacity: 0,
      transform: 'translate3d(25px, 0, 0)'
    },
    to: {
      opacity: 1,
      transform: 'translate3d(0px, 0, 0)'
      // transform:'translateZ(0)'
    },
  },

  root: {
    width: '100%',
    marginTop: -30,
    background: "#f6f7fb",
  },
  container: {
    padding: theme.spacing(1) * 3,
    margin: 'auto',
    maxWidth: 1000,
  },
  progress: {
    margin: theme.spacing(1) * 2,
  },
  iconbuttonprogress: {
    background: 'white',
  },

  // SECTIONS and SUBSECTIONS
  section: {
    backgroundColor: theme.palette.background.paper,
    padding: 24,
    marginBottom: 30,
    borderRadius: theme.shape.borderRadius,
    boxShadow: theme.shadows[3],
    animation: `slide 600ms ${theme.transitions.easing.easeInOut}`
    // animation: 'slide .6s cubic-bezier(.645,.045,.355,1) forwards'
  },
  subsection: {
    marginBottom: 42,
  },
  header: {
    display: 'flex',
    marginBottom: 25,
  },
  subheader: {
    display: 'flex',
    marginBottom: 15,
  },
  featureListHeader: {
    marginTop: 31,
    marginBottom: 42,
    textAlign: 'center',
  },
  subscribeSection: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: 60,
    marginBottom: -20,
  },

  // VISUALISATION related
  textResult: {
    fontWeight: 'bold',
    border: `solid 1px ${theme.palette.background.default}`,
    padding: 15,
    maxWidth: 300,
    borderRadius: 8,
    marginBottom: 25,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    margin: 'auto',
  },
  description: {
    letterSpacing: 0,
    fontSize: '1em',
  },

  // TYPOGRAPHY related
  bold: {
    fontWeight: 'bold',
  },
  textCenter: {
    textAlign: 'center',
  },

  // AVATARS
  subheadingAvatar: {
    width: 20,
    height: 20,
    marginRight: 30,
    fontSize: 14,
  },
});


function SubHeader(props) {
  return (
    <div className={props.classes.subheader}>
      <Avatar component='span' className={props.classes.subheadingAvatar}>
        {props.icon}
      </Avatar>
      <Typography className={props.classes.bold}>
        {props.title}
        <br />
        <Typography className={props.classes.description} gutterBottom>
          {props.description}
        </Typography>
      </Typography>
    </div>
  );
}


// interface QuickScanState {
//   url: string | null;
//   security: null | any; // TODO - plug backend types
//   privacy: null | any; // TODO - plug backend types
//   notFound: boolean;
//   loading: boolean;
// }

// interface QuickScanProps {
//   classes: Record<string, string>;
//   url: string;
// }

class QuickScan extends React.Component {
  render() {
    const classes = this.props.classes;
    const security = JSON.parse(this.props.pageContext.security);
    const privacy = JSON.parse(this.props.pageContext.privacy);
    const url = this.props.pageContext.url;
    const idx = this.props.pageContext.idx;
    const worldmap = JSON.parse(this.props.pageContext.worldmap);

    return (
      <Base idx={idx}>
        <div className={classes.root}>

          {/* Site info */}
          <Grow in={true}>
            <div className={classes.container}>
              <SiteFetchinfo
                site={url}
                message={`Cached analysis preview from ${getFormattedDate()}`}
              />
            </div>
          </Grow>

          {/* Privacy */}
          <div className={classes.container}>
            <div className={classes.section}>

              <Grow in={true}>
                <DivHeader
                  icon={<FingerPrintIcon fontSize='small' />}
                  title={'Privacy'}
                  description={'Data jurisdictions, Processors, Scope, Prevalence and Loading Chain of Third Parties'}
                />
              </Grow>

              {/* 1. Data Flow */}
              <Grow in={true}>
                <div className={classes.subsection}>
                  <SubHeader
                    icon={1}
                    title={'Data Transfer'}
                    description={
                      "Where is your visitor's data being sent, and do you have the right processing agrement for it?"
                    }
                    classes={classes}
                  />
                  <Map
                    worldmap={worldmap}
                    countries={privacy.geoip.countries}
                    markers={privacy.geoip.markers}
                  />
                </div>
              </Grow>

              {/* 2. Third Party Companies */}
              <div className={classes.subsection}>
                <SubHeader
                  icon={2}
                  title={'Third Party Companies'}
                  description={
                    'List of third party scripts, purpose they serve, companies that operate them, and exact script source.'
                  }
                  classes={classes}
                />

                {/* Tracker Table */}
                <TrackerTable trackers={privacy.trackers} />
              </div>

              {/* 3. HTTP Analysis */}
              <div className={classes.subsection}>
                <SubHeader
                  icon={3}
                  title={'HTTP Analysis'}
                  description={'composition of requests'}
                  classes={classes}
                />

                <Grid container>
                  <Grid item sm={6} xs={12} style={{textAlign: 'center'}}>
                    <PieChart
                      data={privacy.https_analysis.first_party}
                      height={300}
                    />
                    <Typography variant='caption'>
                      First Party Requests
                    </Typography>
                  </Grid>
                  <Grid item sm={6} xs={12} style={{textAlign: 'center'}}>
                    <PieChart
                      data={privacy.https_analysis.third_party}
                      height={300}
                    />
                    <Typography variant='caption'>
                      Third Party Requests
                    </Typography>
                  </Grid>
                </Grid>
              </div>

              {/* 4. Loading Dependencies - Sankey */}
              <div className={classes.subsection}>
                <SubHeader
                  icon={4}
                  title={'Dependency Graph - who loads whom'}
                  description={
                    'Dependency graph of which third party scripts are loaded on the page, and which other scripts they load.'
                  }
                  classes={classes}
                />
                <SankeyChart data={privacy.loading_chain} heigh={600} />
              </div>
            </div>
          </div>





          {/* -------------------------- */}
          {/* Security */}
          {/* -------------------------- */}

          <div className={classes.container}>
            <div className={classes.section}>
              <DivHeader
                icon={<LockIcon fontSize='small' />}
                title={'Security'}
                description={'Exposure to common attack vectors and Security practices'}
              />
              <SubSectionSnippet
                title={'Cookies'}
                test={security.tests.cookies}
              />
              <SubSectionSnippet
                title={'Content Security Policy'}
                test={security.tests['content-security-policy']}
              />
              <SubSectionSnippet
                title={'Cross-Origin Resource Sharing'}
                test={
                  security.tests['cross-origin-resource-sharing']
                }
              />
              <SubSectionSnippet
                title={'Public Key Pinning'}
                test={security.tests['public-key-pinning']}
              />
              <SubSectionSnippet
                title={'Redirection'}
                test={security.tests.redirection}
              />
              <SubSectionSnippet
                title={'Referrer Policy'}
                test={security.tests['referrer-policy']}
              />
              <SubSectionSnippet
                title={'Strict Transport Security'}
                test={security.tests['strict-transport-security']}
              />
              <SubSectionSnippet
                title={'Subresource Integrity'}
                test={security.tests['subresource-integrity']}
              />
              <SubSectionSnippet
                title={'MIME Confusion Attacks'}
                test={security.tests['x-content-type-options']}
              />
              <SubSectionSnippet
                title={'Clickjacking Protection'}
                test={security.tests['x-frame-options']}
              />
              <SubSectionSnippet
                title={'Cross Site Scripting Protection'}
                test={security.tests['x-xss-protection']}
              />
            </div>
          </div>

          {/* ---------------------------------- */}
          {/* Compliance */}
          <div className={classes.textCenter} style={{ padding: 25 }}>
            <ArrowUpIcon color='secondary' />
            <br />
            <Typography variant="button" color='textSecondary'>
              THIS IS JUST A PREVIEW
            </Typography>
          </div>
          <div className={classes.textCenter} style={{ background: 'white', padding: 25 }}>
            <Typography variant="button" className={classes.bold}>
              THIS IS WHAT WE CAN DO FOR YOU
            </Typography>
            <br />
            <ArrowDownIcon color='secondary' />
          </div>

          <Features />

        </div>
      </Base>
    );
  }
}

export default withRoot(withStyles(styles)(QuickScan));
